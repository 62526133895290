import {Col, Row} from "../../lib/markup/markup";
import {isLab, isAdministrations} from "../../objects/user_methods";
import {View, Text} from "react-native";
import {EDate, fixHeight} from "../../lib/sugar";
import {AppContext} from "../../screens/contexts";
import {useContext} from "react";
import {Date} from "../../lib/fields/Date";
import {bgDanger, bgSuccess, bold, center, jCenter, mt1, ph1} from "../../const";



export default function ({lab_date, lab_time, pickup, full}) {
    const {user} = useContext(AppContext)
    return <Row
        visible={isLab(user)}>
            <Date
                visible={full}
                editable={false}
                date={lab_date}
                showYear={false}
                weekdayMode={false}
            />
        {isAdministrations(user) &&
            <Col style={{width: 50, ...center, ...jCenter}}>
                {lab_time ?
                    <Text style={bold}>{new EDate(lab_time).isoTime(false)}</Text>
                    :
                    pickup ?
                        <Text style={bold}>самов.</Text>
                        :
                         <View>
                            <Text style={[bgSuccess, ph1]}> --:-- </Text>
                        </View>
                }
            </Col>}
    </Row>
}

import {Dimensions, View} from "react-native";
import {Flex, FlexRow} from "../../../lib/markup/markup";
import {Date} from "../../../lib/fields/Date";
import {center, fRow, jCenter, mb1, ml1, mt1, p1} from "../../../const";
import {useContext, useEffect, useState} from "react";
import {AppContext, CouriersContext} from "../../contexts";
import Loading from "../../../lib/static/Loading";
import {asObject, EDate, fixWidth, lessTablet} from "../../../lib/sugar";
import UnassignedTasks from "./UnassignedTasks";
import AssignedTasks from "./AssignedTasks";
import BtnPrimary from "../../../lib/buttons/BtnPrimary";
import TabBar from "../../../lib/markup/TabBar";
import {H3} from "../../../lib/text/H";


export default function () {
    const {GET, isFocused, POST, route, DELETE, PATCH, getClinic} = useContext(AppContext)
    const [state, setState] = useState({
        ready: false,
        currentDate: new EDate(),
        deliveryNumber: '1',
        department: route?.params?.department ? route?.params?.department: 'lab',
        status: null
    })

    function updateState(args) {
        setState({...state, ...args})
    }

    useEffect(async () => {
        let isMounted = true
        if (isMounted) updateState({ready: false})
        let args = {tasks: [], ready: true, status: ''}
        if (!args.couriers) {
            args.couriers = await GET('main/users/filter', {groups__name: 'курьеры', is_active: 1})
            args.couriers.sort((a, b) => {
                if (a.is_cadcam_courier > b.is_cadcam_courier) return 1
                else if (a.is_cadcam_courier < b.is_cadcam_courier) return -1
                if (a.code > b.code) return 1
                return -1
            })
        }

        class CourierTask {
            static url = 'main/CourierTasks/'
            static stagesUrl = 'main/CourierStages/'
            static callsUrl = 'main/CourierCalls/'
            static ordersUrl = 'main/Orders/'

            constructor({
                            stages = [],
                            calls = [],
                            comment = '',
                            courier = null,
                            courier_time = null,
                            deliveryNumber,
                            direction,
                            finished_at = null,
                            id,
                            lab_date,
                            confirmed
                        }) {

                this.stages = stages
                this.calls = calls
                this.comment = comment
                this.courier = courier
                this.courier_time = courier_time
                this.deliveryNumber = String(deliveryNumber)
                this.direction = String(direction)
                this.finished_at = finished_at
                this.id = id
                this.lab_date = lab_date
                this.confirmed = confirmed
                this.removed = false
                this.selected = false
                for (let field of ['stages', 'calls', 'deliveryNumber', 'direction', 'lab_date']) {
                    if (!this[field]) throw field + " can't be null"
                }
                if (!this.stages.length && !this.calls.length) throw 'CourierTask needs stages or calls'
            }

            async join(task) {
                // if (!(task instanceof CourierTask)) throw 'incorrect task'
                if (this.confirmed) throw 'attempt join to confirmed task'
                if (this.removed) throw 'attempt join to removed task'
                if (task.confirmed) throw 'attempt join confirmed task'
                if (task.removed) throw 'attempt join removed task'
                if (task.direction !== task.direction) throw 'directions are different'
                for (let field of ['calls', 'stages']) {
                    await this.joinObjects(field, task[field])
                }
            }

            async delete() {
                if (this.confirmed) throw 'attempt delete confirmed task'
                await DELETE(CourierTask.url + this.id)
                this.removed = true
            }

            async joinObjects(field, objects) {
                if (typeof field !== 'string') throw 'invalid field'
                for (let obj of objects) {
                    if (!obj) throw "object can't be null"
                    await PATCH(CourierTask[`${field}Url`] + obj.id, {courier_task: this.id})
                    this[field].push(obj)
                }
            }

            async setCourier(courier, ignore_confirm=false) {
                this.courier = courier
                this.selected = false
                await PATCH(CourierTask.url + this.id, {courier})
            }

            async edit(field, value) {
                if (!['courier_time', 'comment', 'confirmed'].includes(field)) throw `can't edit field "${field}"`
                await PATCH(CourierTask.url + this.id, asObject(field, value))
                this[field] = value
            }

            get lab_time() {
                if (!this.stages.length) return
                this.stages.sort((a, b) => {
                    if (a.lab_time > b.lab_time) return 1
                    return -1
                })
                return this.stages[0].lab_time
            }

            static async create({direction, deliveryNumber, lab_date, field, objects}) {
                if (typeof direction === 'undefined') throw "can't create task without direction"
                if (typeof lab_date === 'undefined' || !lab_date) throw "can't create task without lab_date"
                if (!['calls', 'stages'].includes(field)) throw "field must be calls or stages"
                if (!(objects instanceof Array)) throw "incorrect objects, must be array"
                direction = String(direction)
                deliveryNumber = String(deliveryNumber)
                if (!['1', '2'].includes(deliveryNumber)) throw `incorrect deliveryNumber: "${deliveryNumber}"`
                let response = await POST(
                    CourierTask.url,
                    {direction: String(direction), lab_date, deliveryNumber, department: state.department}
                )
                const url = CourierTask[`${field}Url`]
                for (let obj of objects) {
                    await PATCH(url + obj.id, {courier_task: response.id})
                }
                response[field] = objects
                return new CourierTask(response)
            }

            static async load({department, lab_date}) {
                if (!department) throw "department is undefined"
                if (!lab_date) throw "lab_date is undefined"
                let response = await GET(CourierTask.url + 'filter', {department, lab_date})
                response = await CourierTask.deleteEmpty(response)
                return this.joinTasksArray(response, [])
            }

            static async deleteEmpty(src) {
                if (!(src instanceof Array)) throw 'incorrect array'
                let dst = []
                for (let task of src) {
                    if (!task.calls.length && !task.stages.length) {
                        await DELETE(CourierTask.url + task.id)
                    } else dst.push(task)
                }
                return dst
            }

            static async joinTasksArray(src, dst) {
                if (!(src instanceof Array)) throw 'incorrect src'
                if (!(dst instanceof Array)) throw 'incorrect dst'
                for (let task of src) {
                    const index = CourierTask.getTaskIndex(dst, String(task.direction), String(task.deliveryNumber))
                    if (index === -1) dst.push(new CourierTask(task))
                    else dst[index].join(task)
                }
                return dst
            }

            static getTaskIndex(tasks, direction, deliveryNumber) {
                if (!(tasks instanceof Array)) throw 'incorrect tasks array'
                if (!direction) throw 'incorrect direction'
                direction = String(direction)
                deliveryNumber = String(deliveryNumber)
                if (!['1', '2'].includes(deliveryNumber)) throw `incorrect deliveryNumber: ${deliveryNumber}`
                for (let i=0; i<tasks.length; i++) {
                    if (!tasks[i].confirmed && String(tasks[i].direction) === direction &&
                        deliveryNumber === String(tasks[i].deliveryNumber)) {
                        return i
                    }
                }
                return -1
            }
        }

        args.tasks = await CourierTask.load({
            department: state.department,
            lab_date: state.currentDate.isoDate()
        })

        let response = await GET(CourierTask.callsUrl + 'filter',
            {lab_date: state.currentDate.isoDate(), courier_task: null})
        if (response instanceof Array) {
            for (let call of response) {
                if (!call.direction) continue
                call.direction = String(call.direction)
                call.deliveryNumber = String(call.deliveryNumber)
                const i = CourierTask.getTaskIndex(args.tasks, call.direction, call.deliveryNumber)
                if (i===-1) {
                    let task = await CourierTask.create({
                        deliveryNumber: call.deliveryNumber,
                        direction: call.direction,
                        lab_date: call.lab_date,
                        field: 'calls',
                        objects: [call]
                    })
                    args.tasks.push(task)
                } else await args.tasks[i].joinObjects('calls', [call])
            }
        }

        if (state.department === 'lab') {
            let response = await GET(CourierTask.stagesUrl + 'filter',
                {
                    lab_date: state.currentDate.isoDate(),
                    courier_task_id: null,
                    deliveryNumber__in: ['1', '2'],
                    confirmed: 1,
                    pickup: 0
                })
            let n = 1
            if (response instanceof Array) {
                for (let stage of response) {
                    updateState({ready: true, status: `анализирую отправки: ${n}/${response.length}`})
                    n++
                    stage.direction = String(stage.direction)
                    stage.deliveryNumber = String(stage.deliveryNumber)
                    if (!stage.direction) continue
                    const i = CourierTask.getTaskIndex(args.tasks, stage.direction, stage.deliveryNumber)

                    if (i === -1) {
                        let task = await CourierTask.create({
                            direction: stage.direction,
                            deliveryNumber: stage.deliveryNumber,
                            lab_date: stage.lab_date,
                            field: 'stages',
                            objects: [stage]
                        })
                        args.tasks.push(task)
                    } else await args.tasks[i].joinObjects('stages', [stage])
                }
            }
        }

        updateState({ready: true, status: 'сортирую...'})

        for (let i=0; i<args.tasks.length; i++) {
            const id = +args.tasks[i].direction
            if (id) {
                args.tasks[i].clinic = getClinic(id)
                args.tasks[i].direction = args.tasks[i].clinic?.name
            }
            if (!args.tasks[i].courier_time) await args.tasks[i].edit('courier_time', args.tasks[i].lab_time)
        }
        let tasks = args.tasks.slice()
        tasks = tasks.sort((a, b) => {
            if (!a.lab_time) return - 1
            if (!b.lab_time) return 1
            if (a.lab_time > b.lab_time) return 1
            return -1
        })
        args.tasks = tasks

        if (isMounted) updateState(args)

        return () => isMounted = false
    }, [isFocused, state.currentDate])

    if (!state.ready) return <Loading/>
    if (state.status) {
        return <Flex style={[jCenter, center]}>
            <H3>{state.status}</H3>
        </Flex>
    }
    function RightBar() {
        return <View style={[{width: 300, height: Dimensions.get('window').height - 110}, ml1]}>
            <BtnPrimary
                visible={route.name === 'Couriers'}
                style={[mb1, lessTablet() ? mt1 : null]}
                title={'отправить курьеров'}
                onPress={async () => {
                    let tasks = state.tasks.slice()
                    let total = 0
                    for (let task of tasks) {
                        if (!task.confirmed && task.courier) total++
                    }
                    if (!total) return
                    let n = 0
                    for (let i = 0; i < tasks.length; i++) {
                        if (!tasks[i].confirmed && tasks[i].courier) {
                            n++
                            updateState({status: `отправляю... ${n}/${total}`})
                            await tasks[i].edit('confirmed', 1)

                            for (let stage of tasks[i].stages) {
                                await PATCH('main/orders/' + stage.order, {status: 9})
                            }
                        }
                    }
                    updateState({tasks, status: null})
                }}
            />
            <Date
                date={state.currentDate}
                onChange={currentDate => updateState({currentDate})}
                style={[mb1, fixWidth(290)]}
            />
            <UnassignedTasks/>
        </View>
    }

    return <CouriersContext.Provider value={{
        ...state,
        updateState,
    }}>
        {lessTablet() ?
            <TabBar
                titles={['нераспределенные', 'курьеры']}
                items={[
                    {title: 'нераспределенные', body: <RightBar/>},
                    {title: 'курьеры', body: <AssignedTasks/>},
                ]}
            />
            :
            <FlexRow style={[p1, fRow]}>
                <AssignedTasks/>
                <RightBar/>
            </FlexRow>
        }
    </CouriersContext.Provider>
}


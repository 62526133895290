import axios from 'axios'
import asyncStorage from "@react-native-async-storage/async-storage/src/AsyncStorage";
import * as Device from 'expo-device'

export function getApiUrl() {
    let hosts = [
        'https://api.creative-lab.online/',
        'http://127.0.0.1:8000/',
        'http://api.creative-lab.online:8888/'
    ]

    try {
        if (Device.modelName === 'Android SDK built for x86') hosts[1] = 'http://10.0.2.2:8000/'
    } catch (e) {}

    const hostIndex = 0
    return `${hosts[hostIndex]}api/v1/`
}

export const apiUrl = getApiUrl()

export const api = {
    _axios: axios.create({
        baseURL: apiUrl,
        timeout: 5000,
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json'
        },
    }),
    status: null,    // host = 'http://127.0.0.1:8000/'
    // try {
    //     if (Device.modelName === 'Android SDK built for x86') host = 'http://10.0.2.2:8000/'
    // } catch (e) {
    //     host = 'http://127.0.0.1:8000/'
    // }

    async requestPassword(args) {
        try {
            const response = await axios.post(`${apiUrl}main/users/request_password/`, args)
            return response.status
        } catch (error) {
            if (!error.response) return 503
            return error.response.status
        }
    },

    async login(args) {
        try {
            const response = await axios.post(
                `${apiUrl}main/token_obtain/`,
                args
            )
            if (response.status === 200) await api.setTokens(response.data)
            return response.status
        } catch (error) {
            if (!error.response) return 503
            return error.response.status
        }
    },

    async setTokens({access, refresh}) {
        api._axios.defaults.headers['Authorization'] = "JWT " + access;
        await asyncStorage.setItem('access_token', access);
        await asyncStorage.setItem('refresh_token', refresh);
    },

    async logout() {
        asyncStorage.clear()
        api._axios.defaults.headers['Authorization'] = '';
    },

    _completeURL(url) {
        if (url.endsWith('/') || url.includes('?')) return url.toLowerCase()
        return url.toLowerCase() + '/'
    },

    async request({method, url, args, attempt = 0}) {
        try {
            let response
            if (method === 'get') {
                response = await api._axios.get(
                    `${api._completeURL(url)}${args ? '?' + new URLSearchParams(args) : ''}`)
            } else response = await api._axios[method](api._completeURL(url), args)
            return response
        } catch (error) {
            if ([0, 503].includes(error.response.status)) return {status: 503}
            if (error?.response?.status === 401 && attempt < 2) {
                try {
                    const refresh = await asyncStorage.getItem('refresh_token')
                    if (!refresh) return {status: 401}
                    const response = await axios.post(`${apiUrl}main/token_refresh/`, {refresh})
                    if (response.status === 200) {
                        await api.setTokens(response.data)
                        return api.request({method, url, args, attempt: attempt+1})
                    }
                    return {status: 500}
                } catch (error) {
                    if (!error.response) return {status: 503}
                    return error.response
                }
            }
            if (!error.response) return {status: 503}
            if (error.response.status === 500) {
                return {status: 500, data: 'server error'}
            }
            return error.response
        }
    },
    async get(url, args = null) {
        return await api.request({method: 'get', url, args})
    },

    async post(url, args = {}) {
        return await api.request({method: 'post', url, args})
    },

    async patch(url, args = {}) {
        return await api.request({method: 'patch', url, args})
    },

    async delete(url, args = {}) {
        return await api.request({method: 'delete', url, args})
    },
}



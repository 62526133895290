import {Modal, Pressable, Text} from "react-native";
import {useContext, useEffect, useState} from "react";
import {Col, PressRow, Row} from "../../lib/markup/markup";
import {
    absolute,
    bgSecondary, bgWhite,
    center, dangerLight,
    flex,
    mr1, mt1, mt2, p1,
    p2,
    ph1,
    primary,
    shadow,
    success,
    warningLight
} from "../../const";
import {AppContext} from "../../screens/contexts";
import {isManager} from "../../objects/user_methods";
import {TextInput, View} from "react-native";
import {fixWidth, isDesktop} from "../../lib/sugar";
import BtnWarning from "../../lib/buttons/BtnWarning";
import BtnDanger from "../../lib/buttons/BtnDanger";
import BtnPrimary from "../../lib/buttons/BtnPrimary";
import {BtnSuccess} from "../../lib/buttons/BtnSuccess";


export default function ({statuses, otherTechs, patientID, orderID}) {
    return <Row style={ph1}>
        {statuses.map((techStageStatus, key) =>
            <TechStatus key={key} techStageStatus={techStageStatus} patientID={patientID} orderID={orderID}/>)}
        <Text>{otherTechs ? '+' : ''}{otherTechs}</Text>
    </Row>
}

export function TechStatus({techStageStatus, patientID, statusOnly = false, orderID}) {
    const {getLabStatuses, getTech, GET, PATCH, LOG, user} = useContext(AppContext)

    const defaultStatus = {
        statuses: null,
        tech: null,
        techStageStatus,
        reasonShown: false,
        shown: false,
        reason: ''
    }
    const [state, setState] = useState(defaultStatus)

    useEffect(async () => {
        const statuses = await getLabStatuses()
        const tech = await getTech(state.techStageStatus?.tech)
        updateState({statuses, tech})
    }, [])

    function updateState(args) {
        setState({...state, ...args})
    }
    function setStatus(status) {
        PATCH('main/TechStageStatuses/' + state.techStageStatus.id, {status})
        LOG(`${state.statuses[state.techStageStatus.status].name} > ${state.statuses[status].name}`, patientID)
        let args = {orderStatus: 5}
        if (state.statuses[status].code === 'ждё') {
            args.orderStatus = 10
            PATCH('main/orders/' + orderID, {status: args.orderStatus})
        } //waiting
        if (['не', 'ждё'].includes(state.statuses[status].code)) {
            updateState({techStageStatus: {...state.techStageStatus, status}, reasonShown: true})
        } else updateState({techStageStatus: {...state.techStageStatus, status}, shown: false})
    }

    if (!state.statuses || !state.techStageStatus) return null
    return <View>
        <PressRow
            onPress={() => {
                if (!isManager(user) && user.id !== state.techStageStatus.tech) return
                updateState({shown: true})
            }}
        >
            {statusOnly ? null : <Text>{state.tech ? state.tech.code : '???'}:</Text>}
            <Text
                style={[
                mr1,
                getColor(state.statuses[state.techStageStatus.status].id)
            ]}>
                {state.statuses[state.techStageStatus.status].code}
            </Text>
        </PressRow>
        <Modal visible={state.shown} transparent={true}>
            <Pressable style={[flex, center]} onPress={() => updateState({shown: false})}>
                    <Pressable
                        style={[
                            shadow,
                            bgSecondary,
                            p2,
                            absolute,
                            fixWidth(300),
                            {minHeight: 100, top: isDesktop() ? 200: 100}
                        ]}>
                        <Col
                            visible={!state.reasonShown}
                        >
                            {Object.keys(state.statuses).map((status, key) => {
                                const props = {
                                    key: key,
                                    style: mt1,
                                    title: state.statuses[status].name,
                                    onPress: () => setStatus(status)
                                }
                                if (['ждё', 'ник'].includes(state.statuses[status].code.toLowerCase())) {
                                    return <BtnWarning {...props}/>
                                } else if (state.statuses[status].code === 'не') {
                                    return <BtnDanger {...props}/>
                                } else if (state.statuses[status].code === 'раб') {
                                    return <BtnPrimary {...props}/>
                                } else return <BtnSuccess {...props}/>
                            })}
                        </Col>
                        <Col visible={state.reasonShown}>
                            <TextInput
                                multiline={true}
                                numberOfLines={3}
                                style={[p1, bgWhite]}
                                placeholder={'причина...'}
                                onChangeText={reason => updateState({reason})}
                            />
                            <BtnPrimary
                                style={mt2}
                                title={'ОК'}
                                onPress={async () => {
                                    if (state.reason) {
                                        let comment = (await GET('main/stages/' + state.techStageStatus.stage)).comment
                                        if (comment) comment += ' '
                                        comment += '!!!' + state.reason
                                        PATCH('main/stages/' + state.techStageStatus.stage, {comment})
                                    }
                                    updateState({shown: false})
                                }}/>
                        </Col>
                    </Pressable>
            </Pressable>
        </Modal>
    </View>
}

function getColor(code) {
    switch (code) {
        case 1:
            return primary
        case 8:
            return warningLight
        case 9:
            return warningLight
        case 11:
            return dangerLight
        default:
            return success
    }
}

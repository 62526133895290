import {Platform, Pressable, Text, TextInput, TouchableWithoutFeedback, View} from "react-native";
import {
    bgPrimaryLight, bgTransparent,
    bgWhite,
    borderTable, field,
    flex, fRow, mh1,
    mt1,
    padding,
    pb3,
    ph1, secondary, success,
    white
} from "../../../const";
import {useContext, useEffect, useState} from "react";
import {AppContext} from "../../contexts";
import {Flex, Row} from "../../../lib/markup/markup";
import Loading from "../../../lib/static/Loading";
import {AutocorrectField} from "../../../lib/fields/AutocorrectField";
import {fixHeight} from "../../../lib/sugar";
import {ScrollView} from "react-native";
import task from "../couriers/Task";


export default function Comments({department}) {
    const {currentDate} = useContext(AppContext)

    return <ScrollView style={flex}>
        <CommentTitle title={`вызовы 1      ${currentDate.ruDate()}`}/>
        <CourierCallsSection
            department={department}
            deliveryNumber={1}
        />
        <CommentTitle style={mt1} title={`вызовы 2      ${currentDate.ruDate()}`}/>
        <CourierCallsSection
            department={department}
            deliveryNumber={2}
        />

        <CommentTitle style={mt1} title={`заметки        ${currentDate.ruDate()}`}/>
        <CommentsSection
            style={bgWhite} url={`lab/ManagerComments`}
            deps={[currentDate]}
            department={department}
        />

        <View style={mt1}/>
        <CommentsSection
            style={{backgroundColor: `#FDE9AD`}}
            url={`lab/PrepaidDebts`}
            deps={[]}
            department={department}
        />

        <CommentTitle title={`заметки глобальные`} style={mt1}/>
        <CommentsSection style={bgWhite} url={`lab/ManagerGlobalComments`} deps={[]} department={department}/>
    </ScrollView>
}

function CommentsSection({style, url, deps, department}) {
    const {GET, POST, DELETE} = useContext(AppContext)
    let args = {department}
    if (deps.length) args.date = deps[0].isoDate()
    const [comments, setComments] = useState([])
    const isPrepaidTable = url.includes('Prepaid')
    const [shown, setShown] = useState(false)
    document.addEventListener('keydown', (event) => {
        if (event.code === 'Digit1' && (event.shiftKey || event.metaKey)) {
            setShown(!shown)
        }
    });

    async function resizeComments(data) {
        let empty = []
        let newData = []
        if (isPrepaidTable) {
            for (let i = 0; i < data.length; i++) {
                if (!data[i].clinic) empty.push(data[i])
                else newData.push(data[i])
            }
        } else {
            for (let i = 0; i < data.length; i++) {
                if (!data[i].text.length) empty.push(data[i])
                else newData.push(data[i])
            }
        }

        while (empty.length < 2) {
            const data = await POST(url, args)
            empty.push(data)
        }

        while (empty.length > 2) {
            await DELETE(url + '/' + empty[0].id)
            empty.shift()
        }
        setComments(newData.concat(empty))
    }

    useEffect(async () => {
        const data = await GET(url + '/filter', args)
        await resizeComments(data)
    }, deps)

    const RowComponent = isPrepaidTable ? PrepaidRow : CommentRow

    if (!comments.length) return <Loading/>
    if (isPrepaidTable && !shown) return null

    return <View>
        {comments.map((comment, key) =>
            <RowComponent
                style={style}
                url={url}
                comments={comments}
                setComments={setComments}
                key={key}
                index={key}
                onBlur={() => resizeComments(comments)}
            />
        )}
    </View>
}

function PrepaidRow(props) {

    return <Row style={props.style}>
        <Flex>
            <CommentCol {...props} field={'clinic'} placeholder={'клиника'}/>
        </Flex>
        <Flex>
            <CommentCol {...props} field={'prepaid'} placeholder={'аванс'}/>
        </Flex>
        <Flex>
            <CommentCol {...props} field={'debt'} placeholder={'долг'}/>
        </Flex>
    </Row>
}

function CommentRow(props) {
    return <Row style={props.style}>
        <Flex><CommentCol {...props} field={'text'}/></Flex>
    </Row>
}

function CommentCol({url, comments, setComments, index, onBlur, field, placeholder}) {
    const [_text, setText] = useState('')
    const {PATCH} = useContext(AppContext)

    useEffect(() => {
        setText(comments[index][field])
    }, [comments])
    const height = fixHeight(Platform.OS === 'web' ? 20 : 25)
    return <TouchableWithoutFeedback
        style={flex}
        onLongPress={event => {
            let newComments = comments.slice()
            newComments[index].status = newComments[index].status === 1 ? 0 : 1
            PATCH(url + '/' + comments[index].id, {status: newComments[index].status})
            setComments(newComments)
        }}
    >

        <TextInput
            placeholder={placeholder}
            defaultValue={_text}
            onChangeText={value => {
                let args = {}
                args[field] = value
                PATCH(url + '/' + comments[index].id, args)
                let newComments = comments.slice()
                newComments[index][field] = args[field]
                setComments(newComments)
            }}
            onBlur={(event) => {
                let args = {}
                args[field] = event.nativeEvent.text
                PATCH(url + '/' + comments[index].id, args)
                let newComments = comments.slice()
                newComments[index][field] = args[field]
                setComments(newComments)
                onBlur(event)
            }}
            style={[ph1, borderTable, comments[index].status === 1 ? {backgroundColor: 'gold'} : {}, height]}
        />

    </TouchableWithoutFeedback>
}

function CommentTitle({title, style = {}}) {
    if (title) {
        return <Row style={[bgPrimaryLight, padding, {paddingVertical: 5}, style]}>
            <Text style={[white]}>{title}</Text>
        </Row>
    }
    return null
}

function CourierCallsSection({department, deliveryNumber}) {
    const {POST, DELETE, currentDate, GET} = useContext(AppContext)
    const [state, setState] = useState({tasks: [], ready: false})
    const url = 'main/CourierCalls/'

    function updateState(args) {
        setState({...state, ...args})
    }

    async function resizeComments(data) {
        let empty = []
        let newData = []
        for (let i = 0; i < data.length; i++) {
            if (!data[i].clinic && !data[i].address) empty.push(data[i])
            else newData.push(data[i])
        }

        while (empty.length < 2) {
            const data = await POST(url, {department, lab_date: currentDate.isoDate(), deliveryNumber})
            empty.push(data)
        }

        while (empty.length > 2) {
            await DELETE(url + empty[0].id)
            empty.shift()
        }

        updateState({tasks: newData.concat(empty), ready: true})
    }

    useEffect(async () => {
        const tasks = await GET(url + 'manager',
            {lab_date: currentDate.isoDate(), deliveryNumber, department})
        await resizeComments(tasks)
    }, [currentDate])

    function updateTask(index, field, value) {
        let tasks = state.tasks.slice()
        tasks[index][field] = value
        resizeComments(tasks)
    }

    if (!state.ready) return <Loading/>

    return <View>
        {state.tasks.map((task, key) =>
            <Task key={key} {...task} url={url} updateTask={(field, value) => updateTask(key, field, value)}/>
        )}
    </View>
}

function Task({id, clinic, url, updateTask, address, courier_task, checked}) {
    const [state, setState] = useState({ready: false, clinic: null})
    const {getClinic, PATCH, GET} = useContext(AppContext)
    useEffect(async () => {
        let args = {ready: true}
        if (clinic) args.clinic = await getClinic(clinic)
        setState(args)
    }, [clinic])

    if (!state.ready) return null
    const height = fixHeight(Platform.OS === 'web' ? 20 : 25)
    return <Pressable
        style={[fRow]}
        onLongPress={() => {
            PATCH(url + id, {checked: !checked})
            updateTask('checked', !checked)
        }}
    >

        <AutocorrectField
            visible={!address && !courier_task?.confirmed}
            find={async text => await GET('main/clinics/filter', {name__istartswith: text})}
            object={state.clinic}
            placeholder={'клиника'}
            style={[flex, borderTable, height, checked ? {backgroundColor: 'gold'} : white]}
            onBlurSubmit={true}
            onSubmit={clinic => {
                if (clinic) clinic = clinic.id
                PATCH(url + id, {clinic})
                updateTask('clinic', clinic)
            }}
        />

        {!courier_task?.confirmed && !clinic ? <View style={[flex, borderTable]}>
            <TextInput
                placeholder={'адрес'}
                editable={!courier_task?.confirmed}
                style={[
                    Platform.OS === 'web' ? flex : null,
                    height,
                    ph1,
                    checked ? {backgroundColor: 'gold'} : {backgroundColor: field.backgroundColor}
                ]}
                value={address}
                onChangeText={address => {
                    PATCH(url + id, {address})
                    updateTask('address', address)
                }}
            />
        </View> : null}
        {courier_task?.confirmed ?
            <View style={[borderTable, flex, fRow, height, {backgroundColor: 'gold'}]}>
                <Text numberOfLines={1} style={[mh1, flex]}>{clinic ? state?.clinic?.name : address}</Text>
            </View>
            : null}
        {courier_task?.courier ?
            <View style={[borderTable, fRow, height]}>
                <Text
                    numberOfLines={1}
                    style={[mh1, courier_task?.confirmed ? success : secondary, {width: 50}]}>
                    {courier_task?.courier}
                </Text>
            </View>
            : null}
    </Pressable>
}


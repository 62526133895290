import {Row} from "../../markup/markup";
import {TextInput} from "react-native";
import {
    bgWhite,
    borderTable,
    center, danger,
    flex,
    jCenter,
    mr1,
    mt1,
    padding,
    primary,
    stick, stickLeft,
    stickRight
} from "../../../const";
import Btn from "../../buttons/Btn";
import {faPaperPlane, faCamera, faPaperclip} from "@fortawesome/free-solid-svg-icons";
import {useContext, useEffect, useRef, useState} from "react";
import {AppContext} from "../../../screens/contexts";
import * as ImagePicker from "expo-image-picker";
import {View} from "react-native";
import BtnPrimary from "../../buttons/BtnPrimary";
import {compressImageAsync, isDesktop} from "../../sugar";
import {isManager} from "../../../objects/user_methods";
import useEvent from "react-native-web/dist/modules/useEvent";

let camera_redirect = 'https://camera.creative-lab.online'
//camera_redirect = 'http://127.0.0.1:3000'

export function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
export default function Input({createMessage, fastAnswers}) {
    const [text, setText] = useState('')
    const inputRef = useRef()
    const {navigation, user, POST, GET, DELETE} = useContext(AppContext)
    const [buttonsVisible, setButtonsVisible] = useState(true)


    async function sendMessage() {
        await createMessage({body: text})
        setText('')
    }

    async function newCamera() {
        let tmpMessage = await POST('/main/TempMessages/', {})

        window.open(`${camera_redirect}/${tmpMessage.id}/`)
        while (true) {
            await sleep(500)
            tmpMessage = await GET(`main/TempMessages/${tmpMessage.id}/`)
            if (tmpMessage.uri) {
                await createMessage({file: tmpMessage.uri, hidden: tmpMessage.hidden})
                DELETE(`main/TempMessage/${tmpMessage.id}/`)
                return
            }
        }
    }

    return <View>
        <Row visible={fastAnswers}>
            {fastAnswers ?
                fastAnswers.map((button, key) => <BtnPrimary
                    style={isDesktop() ?
                        mr1
                        :
                        key ?
                            stickRight
                            :
                            key === fastAnswers.length-1 ?
                                stickLeft : stick
                }
                    key={key}
                    title={button.title}
                    onPress={() => createMessage({body: button.text})}
                />)
                :
                null
            }
        </Row>
        <Row style={mt1}>
            <TextInput
                onFocus={() => setButtonsVisible(false)}
                onBlur={() => setButtonsVisible(true)}
                ref={inputRef}
                onKeyPress={async ({nativeEvent: {key: keyValue}}) => {
                    if (keyValue === 'Enter') await sendMessage()
                }}
                value={text}
                onChangeText={setText}
                multiline
                numberOfLines={4}
                placeholder={'введите сообщение'}
                style={[borderTable, {height: 50}, bgWhite, flex, padding]}
            />
            <Row style={[jCenter, center]}>
                <Btn
                    visible={isDesktop() && isManager(user)}
                    title={faCamera}
                    textStyle={danger}
                    onPress={newCamera}
                />
                <Btn
                    visible={buttonsVisible}
                    title={faCamera}
                    textStyle={primary}
                    onPress={() => navigation.navigate('Camera')}
                />
                <Btn
                    visible={buttonsVisible}
                    title={faPaperclip}
                    textStyle={primary}
                    onPress={async () => {
                        const uri = await fileUpload()
                        if (!uri) return
                        createMessage({body: '', file: uri, fileName: 'image.png'})
                    }}
                />
                <Btn title={faPaperPlane} textStyle={primary} onPress={sendMessage}/>
            </Row>
        </Row>
    </View>
}

async function fileUpload() {
    let uri = null;
    const storagePermissionGranted = await hasMediaLibraryPermissionGranted();
    if (!storagePermissionGranted) return uri;
    let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [4, 4],
        quality: 1,
    });
    if (!result.cancelled) uri = result.uri;
    return await compressImageAsync(uri)
}

async function hasMediaLibraryPermissionGranted() {
    let granted = false
    const permission = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (!permission.canAskAgain || permission.status === "denied") granted = false
    if (permission.granted) granted = true
    return granted
}



